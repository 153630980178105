import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Modal } from 'antd';
import SiderAction from '../SiderAction';

import { Container } from './ResetSettings.styles';
import { colors } from '../../utils/variables.styles';

class ResetSettings extends React.Component {
  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Ste si istý?',
      content: 'Budú vrátené počiatočné nastavenia modelu garáže.',
      okText: 'Áno',
      okType: 'danger',
      cancelText: 'Nie',
      onOk: this.props.resetConstruction
    });
  }

  render() {
    return (
      <div style={{ color: colors.primaryFontColor }}>
        <Container collapsed={this.props.collapsed}>
          <SiderAction
            icon={<Icon type="redo" />}
            collapsed={this.props.collapsed}
            onClick={this.showDeleteConfirm}
            pointer
            bottom
          >
            Návrat do počiatočných nastavení
          </SiderAction>
        </Container>
      </div>
    );
  }
}

ResetSettings.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  resetConstruction: PropTypes.func.isRequired
};

export default ResetSettings;
