import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Modal,
  Divider,
  Typography,
  Checkbox,
  Button
} from 'antd';
import EditWrapper from '../../components/EditWrapper';

import { colors } from '../Construction/utils/convertCalculationResponse';
import { colors as colorsVariables } from '../../utils/variables.styles';

import {
  Content,
  StyledTitle,
  SelectedWrapper
} from '../../components/SelectEdit/SelectEdit.styles';

import SelectEdit from '../../components/SelectEdit';
import selector from './colorEditor.selector';
import { setSelected, setAllColors } from '../Construction/construction.reducer';

import { infoShape } from '../../types/info.types';

import {
  ModalContainer,
  ColorContainer,
  ColorWrapper,
  Color,
  ItemContainer,
  ColorName
} from './colorEditor.styles';

const { Title } = Typography;

class ColorEditor extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    setAllColors: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    /* eslint-disable */
    available: PropTypes.object,
    colors: PropTypes.arrayOf(PropTypes.object),
	walls_color_info: infoShape,
    walls_color_selected: PropTypes.number,
    doors_back_color_info: infoShape,
    doors_back_color_selected: PropTypes.number,
    doors_front_color_info: infoShape,
    doors_front_color_selected: PropTypes.number,
    doors_left_color_info: infoShape,
    doors_left_color_selected: PropTypes.number,
    doors_right_color_info: infoShape,
    doors_right_color_selected: PropTypes.number,
    gates_color_info: infoShape,
    gates_color_selected: PropTypes.number,
    roof_color_info: infoShape,
    roof_color_selected: PropTypes.number,
    tooling_color_info: infoShape,
    tooling_color_selected: PropTypes.number,
    
    /* eslint-enable */
  }

  static defaultProps = {
    available: [],
    colors: [],
    roof_color_info: null,
    roof_color_selected: null,
    tooling_color_info: null,
    tooling_color_selected: null,
    walls_color_info: null,
    walls_color_selected: null,
    gates_color_info: null,
    gates_color_selected: null,
    doors_front_color_info: null,
    doors_front_color_selected: null,
    doors_back_color_info: null,
    doors_back_color_selected: null,
    doors_left_color_info: null,
    doors_left_color_selected: null,
    doors_right_color_info: null,
    doors_right_color_selected: null
  }

  state = {
    modalVisible: false,
    info: null,
    name: null,
    toAll: false,
    selected: null,
    isWooden: false
  }

  confirm = () => {
    if (this.state.toAll && this.state.selected) {
      this.props.setAllColors(this.state.selected, this.state.isWooden);
    } else if (this.state.selected) {
      this.props.setSelected(this.state.name, this.state.selected);
    }

    this.closeModal();
  }

  handleChange = (value, isWooden) => {
    this.setState({ selected: value, isWooden });
  }

  openModal = (info, name) => {
    this.setState({
      modalVisible: true,
      info,
      name,
      toAll: false
    });
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
      name: null
    });

    setTimeout(() => {
      this.setState({
        info: null,
        selected: null,
        isWooden: false
      });
    }, 300);
  }

  renderOption = (info, selected, name) => {
    if (selected) {
      return (
        <EditWrapper
          isEdit={false}
          onEditClick={() => { this.openModal(info, name); }}
          noEdit={false}
          key={info.name}
        >
          <Content>
            <StyledTitle>{info.name}:</StyledTitle>
            <SelectedWrapper>
              {
                selected ? this.renderItem(
                  this.props.colors.find((el) => el.id === selected, info.name)
                ) : 'Brak'
              }
            </SelectedWrapper>
          </Content>
        </EditWrapper>
      );
    }

    return null;
  }

  renderItem = (item, key, name, isWooden = false) => {
    const selected = (this.state.selected || this.props[`${this.state.name}_selected`]) === item.id;

    return (
      <ItemContainer
        key={item.color_name + key}
        click={!!name}
        onClick={name ? () => { this.handleChange(item.id, isWooden); } : () => {}}
      >
        <ColorContainer>
          <Color style={{ background: item.hexa }} />
        </ColorContainer>
        <ColorName selected={selected && !!this.state.name}>
          {item.color_name}
        </ColorName>
      </ItemContainer>
    );
  }

  render() {
    return (
      this.props.loaded ? (
        <React.Fragment>
          <div style={{ padding: '0 24px', background: colorsVariables.mainLight }}>
            {
              colors.map((el) => (
                this.renderOption(
                  this.props[`${el}_info`],
                  this.props[`${el}_selected`],
                  el
                )
              ))
            }
          </div>
          <Modal
            title={this.state.info && this.state.info.name}
            destroyOnClose
            visible={this.state.modalVisible}
            footer={[
              <Button key="back" onClick={this.closeModal} style={{ color: colorsVariables.main }}>
                Zrušiť
              </Button>,
              <Button key="submit" type="primary" onClick={this.confirm}>
                Vybrať
              </Button>
            ]}
            onCancel={this.closeModal}
            width={1000}
          >
            <div className="ant-alert ant-alert-error ant-alert-no-icon">
              <Checkbox
                checked={this.state.toAll}
                onChange={(e) => { this.setState({ toAll: e.target.checked }); }}
              >
                {this.state.toAll}
                Použiť farbu pre všetky prvky garáže:
              </Checkbox>
            </div>
            <ModalContainer>
              <ColorWrapper>
                <Title level={4}>Matné:</Title>
                {
                  this.props.available.matt.map((el, key) => this.renderItem(
                    el,
                    key,
                    this.state.info && this.state.info.name,
                  ))
                }
              </ColorWrapper>
              <ColorWrapper>
                <Title level={4}>Lesklé:</Title>
                {
                  this.props.available.smooth.map((el, key) => this.renderItem(
                    el,
                    key,
                    this.state.info && this.state.info.name
                  ))
                }
              </ColorWrapper>
              {
                this.state.name !== 'roof_color' ? (
                  <ColorWrapper>
                    <Title level={4}>Textúra dreva:</Title>
                    {
                      this.props.available.wooden.map((el, key) => this.renderItem(
                        el,
                        key,
                        this.state.info && this.state.info.name,
                        true
                      ))
                    }
                  </ColorWrapper>
                ) : null
              }
            </ModalContainer>
            <Divider>{this.state.info && this.state.info.info_title}</Divider>
            {this.state.info && this.state.info.info_content}
          </Modal>
        </React.Fragment>
      ) : <SelectEdit loading />
    );
  }
}

const mapDispatchToProps = {
  setSelected,
  setAllColors
};

export default connect(selector, mapDispatchToProps)(ColorEditor);
