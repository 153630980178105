import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Layout } from 'antd';

import EditableSection from '../../../components/EditableSection';
import ResetSettings from '../../../components/ResetSettings';

import DimensionsEditor from '../../../containers/DimensionsEditor';
import GatesEditor from '../../../containers/GatesEditor';
import ConstructionExtras from '../../../containers/ConstructionExtras';
import PressingEditor from '../../../containers/PressingEditor';
import AnchoringEditor from '../../../containers/AnchoringEditor';
import ClosingEditor from '../../../containers/ClosingEditor';
import BoltEditor from '../../../containers/BoltEditor';
import InsideEditor from '../../../containers/InsideEditor';
import MoistureEditor from '../../../containers/MoistureEditor';
import PadlockEditor from '../../../containers/PadlockEditor';
import ColorEditor from '../../../containers/ColorEditor';
import ToolingEditor from '../../../containers/ToolingEditor';
import { Nested } from '../../../containers/ConstructionExtras/ConstructionExtras.styles';

import { sider } from '../../../utils/variables.styles';

import {
  SiderContainer
} from './Sider.styles';
import useWindowDimensions from "../../../utils/useWindowDimensions";


const SiderComponent = ({
  collapsed,
  resetSettings
}) => {
  const {width} = useWindowDimensions();
  return (
    <Layout.Sider
      width={sider.width}
      style={{
        maxWidth: '100vw',
        marginTop: width > 1000 ? 0 : 80
      }}
      collapsedWidth={sider.collapsedWidth}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <SiderContainer collapsed={collapsed}>
        <DimensionsEditor collapsed={collapsed}/>
		 <GatesEditor collapsed={collapsed}/>
		 <EditableSection
          collapsed={collapsed}
          icon={<Icon type="build"/>}
          title="Klampiarske prvky"
        >
         <Nested>
			<ToolingEditor />
			{/* <StructureEditor />
			<InsideEditor /> */}
		</Nested>
		
        </EditableSection>
		<ConstructionExtras collapsed={collapsed}/>
        <EditableSection
          collapsed={collapsed}
          icon={<Icon type="bg-colors"/>}
          title="Farby garáže"
        >
          <ColorEditor collapsed={collapsed}/>
        </EditableSection>
       <EditableSection
          collapsed={collapsed}
          icon={<Icon type="table"/>}
          title="Profil plechu"
        >
          <PressingEditor collapsed={collapsed}/>
        </EditableSection>
        
        
        <EditableSection
          collapsed={collapsed}
          icon={<Icon type="reconciliation"/>}
          title="Doplňujúce informácie"
        >
          {/* <ToolingEditor /> */}
          <AnchoringEditor collapsed={collapsed}/>
          <ClosingEditor collapsed={collapsed}/>
          <InsideEditor collapsed={collapsed}/>
          <MoistureEditor collapsed={collapsed}/>
          <BoltEditor collapsed={collapsed}/>
          <PadlockEditor collapsed={collapsed}/>
        </EditableSection>
      </SiderContainer>
      <ResetSettings
        resetConstruction={resetSettings}
        collapsed={collapsed}
      />
    </Layout.Sider>
  )
};

SiderComponent.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  resetSettings: PropTypes.func.isRequired
};

export default SiderComponent;
