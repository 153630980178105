import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Icon } from 'antd';

import { open } from '../../../../layout/components/Drawer/Drawer.reducer';

import Box from '../../../Box';

import constructionPropTypes from './Header.types';

import {
  Wrapper,
  StyledImg,
  BoxWrapper,
  IconWrapper,
  TextWrapper,
  ConstructionWrapper,
  IIConWrapper
} from './Header.styles';

import styled from "styled-components";

const WrapperDiv = styled.div`
  @media (max-width: 1000px) {
    padding-top: 30px;
  }
`;

const Constructions = ({
  activeConstruction,
  constructions,
  onConstructionChange,
  openForm
}) => (
    <WrapperDiv>
  <Wrapper>
    {
      constructions.map((key) => (
        <BoxWrapper key={key.attributes.slope}>
          <Box
            pointer
            active={key.id === activeConstruction}
            onClick={() => { onConstructionChange(key.id); }}
            clear
          >
            <ConstructionWrapper title={key.attributes.name}>
              <StyledImg src={key.attributes.img} alt={key.attributes.name} />
              {
                window.innerWidth > 640 ? (
                  <TextWrapper>{key.attributes.name}</TextWrapper>
                ) : null
              }
            </ConstructionWrapper>
          </Box>
        </BoxWrapper>
      ))
    }
    <BoxWrapper>
      <Box
        pointer
        clear
        onClick={() => { openForm(false); }}
      >
        <IIConWrapper>
          <IconWrapper>
            <Icon type="question" />
          </IconWrapper>
          {
            window.innerWidth > 640 ? (
              <TextWrapper>
                Neštandardná garáž
              </TextWrapper>
            ) : null
          }
        </IIConWrapper>
      </Box>
    </BoxWrapper>
  </Wrapper>
      </WrapperDiv>
);

Constructions.propTypes = {
  activeConstruction: PropTypes.number.isRequired,
  constructions: PropTypes.arrayOf(constructionPropTypes).isRequired,
  openForm: PropTypes.func.isRequired,
  onConstructionChange: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  openForm: open
};

export default connect(null, mapDispatchToProps)(Constructions);
