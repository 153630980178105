import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { Icon } from 'antd';
import EditableSection from '../../components/EditableSection';

import DoorsEditor from '../DoorsEditor';
import HolesEditor from '../HolesEditor';
import WindowsEditor from '../WindowsEditor';
// import InsideEditor from '../InsideEditor';
// import StructureEditor from '../StructureEditor';
import ToolingEditor from '../ToolingEditor';

// import ConstructionAddons from '../../components/ConstructionAddons/ConstructionAddons';

// const Wrapper = styled.div`
//   padding: 0 24px 10px 24px;
// `;

import { Nested } from './ConstructionExtras.styles';

const ConstructionExtras = ({
  collapsed
}) => (
  <EditableSection
    collapsed={collapsed}
    icon={<Icon type="appstore" />}
    title="Konštrukčné doplnky"
  >
   
   
    <DoorsEditor collapsed={collapsed} />
  
    <WindowsEditor collapsed={collapsed} />
    {/* <EditableSection
      collapsed={collapsed}
      title="Lewa ściana"
    >
      <Wrapper>
        <ConstructionAddons />
      </Wrapper>
    </EditableSection>
    <EditableSection
      collapsed={collapsed}
      title="Prawa ściana"
    >
      <Wrapper>
        <ConstructionAddons />
      </Wrapper>
    </EditableSection>
    <EditableSection
      collapsed={collapsed}
      title="Tylna ściana"
    >
      <Wrapper>
        <ConstructionAddons />
      </Wrapper>
    </EditableSection> */}
  
  </EditableSection>
);

ConstructionExtras.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default ConstructionExtras;
