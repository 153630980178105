import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';

import {
  Layout,
  Icon,
  Drawer as AntdDrawer
} from 'antd';
import Sider from './components/Sider';
import Drawer from './components/Drawer';

import {
  loadConfiguration,
  resetSettings
} from '../containers/Construction/construction.reducer';

import Scene from '../containers/Scene';
import { sider } from '../utils/variables.styles';
import ContainerHeader from '../containers/ContainerHeader';
import ConstructionFooter from '../containers/ConstructionFooter';

import { ContainerHeaderWrapper } from './App.styles';

import useWindowDimensions from '../utils/useWindowDimensions';

const { Header, Content } = Layout;

const App = ({
  loadConfigurationCallback,
  resetSettingsCallback
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    loadConfigurationCallback();
  }, []);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ marginTop: 16, overflow: 'hidden' }}>
      {
        width > 1000 ? (
          <Sider collapsed={collapsed} resetSettings={resetSettingsCallback} />
        ) : (
          <AntdDrawer
            visible={collapsed}
            bodyStyle={{ padding: 0 }}
            width={sider.width}
            style={{ maxWidth: '100vw' }}
            placement="left"
            onClose={toggleSider}
            closable={false}
          >
            <button
              className={"ant-drawer-close"}
              style={{
                height: 64,
                width: sider.width,
                borderRadius: 8,
                left: 0,
                display: collapsed ? 'block' : 'none'
              }}
              onClick={toggleSider}
            >
              Prejst' na konfiguráciu Vašej garáže
            </button>
            <Sider collapsed={false} resetSettings={resetSettingsCallback} />
          </AntdDrawer>
        )
      }
      <Layout>
        <Header style={{ background: '#b10817', padding: 0, marginBottom: 16 }}>
          <Icon
            style={{
              fontSize: '18px',
              lineHeight: '64px',
              padding: '0 24px',
              cursor: 'pointer',
              transition: 'color .3s'
            }}
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={toggleSider}
          />
          <span
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '-16px',
              lineHeight: '64px',
              cursor: 'pointer',
              transition: 'color .3s'
            }}
            onClick={toggleSider}
          >
            Vyberte parametre garáže (kliknite sem)
          </span>
        </Header>
        <ContainerHeaderWrapper>
          <ContainerHeader />
        </ContainerHeaderWrapper>
        {
          width > 1000 ? (
            <StickyContainer style={{ height: '100%' }}>
              <Sticky style={{ height: '100%' }}>
                {({ style, isSticky }) => (
                  <Content style={{ ...style, width: 784, margin: '0 0 20px 16px' }}>
                    <Scene
                      big={collapsed}
                    />
                    <ConstructionFooter isSticky={isSticky} />
					</Content>
                )}
              </Sticky>
            </StickyContainer>
          ) : (
            <Content style={{ margin: '0 16px 20px 16px' }}>
              <Scene
                big={collapsed}
              />
              <ConstructionFooter />
            </Content>
          )
        }
      </Layout>
	  <Drawer />
    </Layout>
  );
};

App.propTypes = {
  loadConfigurationCallback: PropTypes.func.isRequired,
  resetSettingsCallback: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  loadConfigurationCallback: loadConfiguration,
  resetSettingsCallback: resetSettings
};

export default connect(null, mapDispatchToProps)(App);
