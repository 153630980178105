import React from 'react';
import PropTypes from 'prop-types';

import { Breadcrumb } from 'antd';
import Box from '../../components/Box';
import Header from './components/Header';

import constructionPropTypes from './components/Header/Header.types';

import {
  Container,
  ConstructionsWrapper
} from './ConstructionHeader.styles';

const ConstructionHeader = ({
  activeConstruction,
  constructions,
  onConstructionChange
}) => (
  <Container>
    <Container>
      <Box
        noTop
        clear
        loading={activeConstruction === null}
        loaderHeight={208}
        wide
      >
        <ConstructionsWrapper>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Zvoľte typ strechy</Breadcrumb.Item>
          </Breadcrumb>
          {
            activeConstruction ? (
              <Header
                constructions={constructions}
                activeConstruction={activeConstruction}
                onConstructionChange={onConstructionChange}
              />
            ) : null
          }
        </ConstructionsWrapper>
      </Box>
    </Container>
	
  </Container>
);

ConstructionHeader.propTypes = {
  constructions: PropTypes.arrayOf(constructionPropTypes).isRequired,
  onConstructionChange: PropTypes.func.isRequired,
  activeConstruction: PropTypes.number
};

ConstructionHeader.defaultProps = {
  activeConstruction: null
};

export default ConstructionHeader;
