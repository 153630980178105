import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Icon } from 'antd';
import EditableSection from '../../components/EditableSection';
import LeftGateEditor from '../../containers/LeftGateEditor';
import SelectEdit from '../../components/SelectEdit';

import { StyledImg, Container } from './GatesEditor.style';

import selector from './gatesEditor.selector';

import RightGateEditor from '../RightGateEditor';
import EditGatesModal from '../EditGatesModal';

import { infoShape } from '../../types/info.types';
import { availableType } from '../../types/available.types';

const GatesEditor = ({
  collapsed,
  gateImg,
  loaded,
  gate_count_available,
  gate_count_selected,
  gate_count_info,
  gate_count
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
  	
    <EditableSection
      collapsed={collapsed}
      icon={<Icon type="gateway" />}
      title="Parametre brány"
    >
	
      {
        loaded ? (
          <SelectEdit
            loaded={loaded}
            title="Typ a nastavenia brány"
            isEdit
            onEditClick={() => setModalOpen(true)}
            options={[]}
          />
        ) : null
      }
      {
        loaded ? (
          <SelectEdit
            selected={gate_count_selected}
            loaded={loaded}
            title={gate_count_info.name}
            options={gate_count_available.map((el) => ({
              id: el.id,
              value: `${el.attributes.value}`
            }))}
          />
        ) : null
      }
      <LeftGateEditor openModal={() => { setModalOpen(true); }} />
      {
        gate_count > 1 ? (
          <RightGateEditor openModal={() => { setModalOpen(true); }} />
        ) : null
      }
      <Container>
        {
          gateImg ? (
            <StyledImg src={gateImg} />
          ) : null
        }
      </Container>
      <EditGatesModal visible={isModalOpen} closeModal={() => { setModalOpen(false); }} />
	  
    </EditableSection>
  );
};

GatesEditor.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  gate_count: PropTypes.number,
  gate_count_available: PropTypes.arrayOf(availableType),
  gate_count_info: infoShape,
  gate_count_selected: PropTypes.number,
  gateImg: PropTypes.string,
  loaded: PropTypes.bool
};

GatesEditor.defaultProps = {
  gateImg: null,
  gate_count: null,
  gate_count_available: [],
  gate_count_info: null,
  gate_count_selected: null,
  loaded: false
};

export default connect(selector, null)(GatesEditor);
