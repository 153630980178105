import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, Divider, Button } from 'antd';

import selector from './editGatesModal.selector';
import GatesSubtypes from '../../components/GatesSubtypes';

import { setSelected } from '../Construction/construction.reducer';
import { infoShape } from '../../types/info.types';

class EditGatesModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    info: infoShape,
    loaded: PropTypes.bool,
    selected: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    subtypes: PropTypes.object,
    visible: PropTypes.bool,
    width: PropTypes.number
  }

  static defaultProps = {
    selected: null,
    width: null,
    subtypes: null,
    info: null,
    visible: false,
    loaded: false
  }

  state = {
    selectedSubtype: null,
    selectedId: this.props.selected
  }

  setActiveSelectedSubtype = (activeSubtype) => {
    this.setState({ selectedSubtype: activeSubtype });
  }

  handleItemSelected = (id) => {
    this.setState({ selectedId: id });
    this.props.setSelected('gate', id);
  }

  closeModal = () => {
    this.setState({ selectedId: null });
    this.props.closeModal();
  }

  confirm = () => {
    if (this.state.selectedId) {
      this.props.setSelected('gate', this.state.selectedId);
    }
    this.closeModal();
  }

  render() {
    return (
      this.props.loaded ? (
        <Modal
          width={1150}
          title={this.props.info.name}
          visible={this.props.visible}
          footer={[
            <Button key="submit" type="primary" onClick={this.confirm}>
              Uložiť a zavrieť
            </Button>
          ]}
          onCancel={this.closeModal}

        >
          <GatesSubtypes
            subtypes={this.props.subtypes}
            selectedId={this.state.selectedId || this.props.selected}
            selectedSubtype={
              this.state.selectedSubtype
                || (this.props.width >= 500 ? (
                  Object.keys(this.props.subtypes)[2]
                ) : (
                  Object.keys(this.props.subtypes)[1]
                ))
            }
            onItemSelected={this.handleItemSelected}
            setActiveSelectedSubtype={this.setActiveSelectedSubtype}
            width={this.props.width}
          />
          <Divider>{this.props.info.info_title}</Divider>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: this.props.info.info_content }} />
        </Modal>
      ) : null
    );
  }
}

const mapDispatchToProps = {
  setSelected
};

export default connect(selector, mapDispatchToProps)(EditGatesModal);
